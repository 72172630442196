.section--footer {
	padding-bottom: 60px;
  font-size: 90%;
  background-color: #373B44;
}

.section--footer h2:after {
  background-color: transparent;
}

.footer__copyright {
  text-align: center;
  font-size: 90%;
  line-height: 145%;
  color: #888;
}