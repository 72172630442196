.showroom {
  margin-top: 15px;
}

.section--showroom {
  background-color: $section-background;
}

.showroom__container {
  list-style: none;
  width: 100%;
}

.showroom__container li {
  font-size: 0;
  display: block;
  float: left;
  width: 25%;

  @media (max-width : ($breakpoint-medium - 1)) {
    width: 50%;
  }
}

.display__img {
  background-color: black;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.display__img img {
  width: 100%;
  height: auto;
  transform: scale(1);
  opacity: 0.85;
  transition: transform 0.5s, opacity 0.5s;
}

.display__img img:hover {
  opacity: 1.0;
  transform: scale(1.15);
}