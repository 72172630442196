@-webkit-keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 0.95;}
}

@keyframes fadeIn {
  0% {opacity: 0;}
  100% {opacity: 0.95;}
}

@-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}