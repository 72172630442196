.section--contact {
  padding-bottom: 0px;
}

.contact__form {
  font-size: 90%;
  line-height: 145%;
}

.contact__form-input {
  font-weight: 300;
}

.contact__emailform {
  padding-left: 5%;
  @media (max-width : ($breakpoint-medium - 1)) {
    padding-left: 0%;
  }
}

.contact__info {
  text-align: center;
  @media (max-width : ($breakpoint-medium - 1)) {
    margin-top: 10px;
  }
}

.contact__info-container {
  display: inline-block;
  text-align: left;
}

.contact__address {
  font-size: 90%;
  line-height: 145%;
  margin-bottom: 10px;
}

.contact__phone {
  font-size: 90%;
  line-height: 145%;
}

.contact__email {
  font-size: 90%;
  line-height: 145%;
}

.contact__social {
    font-size: 90%;
    line-height: 145%;
    margin-top: 20px;
}

.company-social {
  list-style: none;
}

.company-social li a img {
  width: 100%;
}

.company-social li {
  float: left;
  width: 50px;
}

.map {
  width: 100%;
  height: 400px;
}

.map__img img {
  width: 40px;
}

.map__img {
  position: absolute;
  transform: translate(-50%, -50%)
}