.service {
  padding-top: 10px;
}

.service__content {
  @media (max-width : ($breakpoint-medium - 1)) {
    margin-bottom: 30px;
  }
}

.service__desc p {
  font-size: 90%;
  line-height: 145%;
  @media (max-width : ($breakpoint-medium - 1)) {
    margin: 0 0%;
  }
}

.service__desc h3 {
  text-align: center;
}

.service__img img {
  display: block;
  margin-bottom: 10px;
  width: 70px;
  margin: 0 auto;
}