.intro {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(./img/intro.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;

  @media (max-width : ($breakpoint-medium - 1)) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(./img/intro_s.jpg);
  }
}

.intro__text {
  position: absolute;
  width: 80%;
  height: 200px;
  top: 50%;
  left: 40%;
  transform: translate(-25%, -25%);
  @media (max-width : ($breakpoint-medium - 1)) {
    width: 60%;
    left: 20%;
    transform: none;
    top: 58%;
  }
}

.intro__text h1 {
  color: #fff;
  font-weight: 300;
  font-size: 400%;
  font-family: $font-handwriting;
  letter-spacing: 1px;
  word-spacing: 4px;
  margin-bottom: 2px;  
  @media (max-width : ($breakpoint-medium - 1)) {
    font-size: 200%;
    line-height: 1.1;
  }
}

.intro__text p {
  color: #fff;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: none;
  word-spacing: 4px;
  text-align: left;
  margin-bottom: 30px;
}

.intro__text .btn {
  margin-right: 20px;
  margin-bottom: 20px;
}

.btn-getintouch {
  @media (max-width : ($breakpoint-medium - 1)) {
    display: none;
  }
}

.btn-showmemore {
  @media (max-width : ($breakpoint-medium - 1)) {
    display: none;
  }
}

.intro__downlink {
  position: absolute;
  bottom: 0%;
  left: 50%;
  width: 48px;
  height: 18px;
  margin-left: -24px;
  background: url(./img/downlink.svg) no-repeat 0 0;
  @media screen and (min-width: $breakpoint-medium - 1) {
    display:none;
  }
}