.card {
  margin: 10px;
  text-align: center;
  float: left;
  padding: 40px 0px;
  background-color: $lightgray;
  width: 280px;

  @media (max-width : ($breakpoint-medium - 1)) {
    float: none;
  }
}

.card__name {
  color: $sitecolor;
  font-weight: bolder;
  text-align: center;
}

.card__desc {
  padding-top: 20px;
  font-size: 90%;
}

.card__img {
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 200px;
  margin: 0 auto;
  border-radius: 50%;
}