.about {
  text-align: center;
}

.section--about {
  background-color: transparent;
}

.about__container {
  display: inline-block;
  margin-top: 3px;
}