//============================== General

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;
  &.ReactModal__Body--open {
    overflow: hidden;
  }
}

h2, h3 {
  font-weight: 300;
  text-transform: uppercase;
  word-spacing: 2px;
  margin-bottom: 30px;
}

h2 {
  font-size: 180%;
  text-align: center;
  letter-spacing: 1px;
}

h2:after {
  display: block;
  height: 2px;
  background-color: $sitecolor;
  content: " ";
  width: 100px;
  margin: 0 auto;
  margin-top: 30px;
}

h3 {
  font-size: 110%;
  margin-bottom: 15px;
  text-align: left;
}

.long-copy {
  line-height: 145%;
  padding: 0 15%;
  @media (max-width : ($breakpoint-medium - 1)) {
    width: 100%;
    padding: 0 2%;
  }
}

.pull-right {
  float: right;
}

.clear-fix { zoom: 1}
.clear-fix:after {
    content: '.';
    clear: both;
    display: block;
    height: 0;
    visibility: hidden;
}

//============================== Containers

.wrapper {
  width: 100%;
  min-height: 100vh;
  font-size: $wrapper-fontsize;
  font-family: $wrapper-font;
  line-height: $wrapper-lineheight;
  color: $textcolor;
  font-weight: 300;
}

//============================== Buttons

.row {
  width: 1140px;
  max-width: 100%;
  margin: 0 auto;
}

.row-small {
  max-width: 96%;
}

.btn__container {
  display: inline-block;
  line-height: 100%
}

.btn,
input[type=submit] {
  display: inline-block;
  padding: 6px 20px 8px 20px;
  text-decoration: none;
  border-radius: 200px;
  color: #fff;
  vertical-align: middle;
  transition: background-color $transition-fast, border $transition-fast, color $transition-fast;
}

.btn-full,
input[type=submit] {
  border: 1px $sitecolor solid;
  background-color: $sitecolor;
}

.btn-ghost {
  border: 1px $sitecolor solid;
  color: $sitecolor;
}

.btn-full:hover,
.btn-full:active,
input[type=submit]:hover,
input[type=submit]:active  {
  border: 1px $sitecolor-dark solid;
  background-color: $sitecolor-dark;
}

.btn-ghost:hover,
.btn-ghost:active {
  border: 1px $sitecolor-dark solid;
  background-color: $sitecolor-dark;
  color: #fff;
}

//============================== Links

a {
  text-decoration: none;
  color: $sitecolor;
}