@import url('https://fonts.googleapis.com/css?family=Lato:100,300,300i,400');
@import url('https://fonts.googleapis.com/css?family=Dancing+Script');

//============================== Fonts

$wrapper-fontsize     : 20px;
$wrapper-lineheight   : (20/15); // 1.33333
$wrapper-font         : 'Lato', 'Arial', sans-serif;

$font-handwriting     : 'Dancing Script', cursive;

$bold: bold; // font-weight for bold text (NOT bold-font but system / google fonts)

//==============================  Colors
// https://flatuicolors.com/palette/nl

$s-green: #009432;
$s-yellow: #F79F1F;
$s-yellow-dark: #db8508;

$sitecolor: $s-yellow;
$sitecolor-dark: $s-yellow-dark;
$section-background: #f4f4f4;

$textcolor: #273134;

$darkgray: $textcolor;
$lightgray: #F9F9F9;

$bordercolor: #E4E4E4;

//==============================  Breakpoints

$breakpoint-large: 1080px;
$breakpoint-medium: 768px;
$breakpoint-small: 640px;

//==============================  Transition time

$transition-fast: 0.3s;
$transition-slow: 1.0s;
