.contact__form {
  background-color: $lightgray;
  padding: 10px;
  margin: 0 auto;
}

input[type=text],
input[type=email],
select,
textarea {
  width: 100%;
  padding: 7px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

textarea {
  resize: none;
}

*:focus {
  outline: none;
}