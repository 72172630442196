nav {
  position: fixed;
  width: 100%;
  z-index: 99;
}

.navigation {
  overflow: hidden;
  width: 100%;
  padding: 0px 10px;
  transition: 0.3s;
}

.navigation {
  & .is-top {
    background-color: none;
  }
}

.navigation {
  &.is-scrolled {
    background: $sitecolor; /* fallback for old browsers */
    background: -webkit-linear-gradient(to left,$sitecolor, #373B44); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, $sitecolor, #373B44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}

.navigation-desktop {
  float: right;
  @media (max-width : ($breakpoint-medium - 1)) {
    display: none;
  }
}

.navigation-mobile {
  display: none;
  @media (max-width : ($breakpoint-medium - 1)) {
    display: block;
  }
}

.navigation__list {
  float: right;
  list-style: none;
  margin-top: 50px;
  margin-right: 50px;
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.2);
  z-index: 100;

  &.navigation-mobile {
    background: $darkgray;
    opacity: 0.9;
    margin-top: 15%;
    margin-right: 13%;

    -webkit-animation-duration: 250ms;
    animation-duration: 250ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;

    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
  }
}

.navigation__list {
  &.is-scrolled {
    margin-top: 0px;
    padding: 15px 0px;
    background: none;
  }
}

.navigation__list li {
  display: inline-block;
  margin-right: 40px;
}

.navigation__list.navigation-mobile li {
  display: block;
  padding: 8px 0px;
}

.navigation__list li:last-child {
  margin-right: 0px;
}

.navigation__list li a {
  padding: 8px 0px;
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 18/20;
  border-bottom: 2px solid transparent;
  transition: border-bottom $transition-fast;
}

.navigation__list li .is-active {
  padding: 8px 0px;
  border-bottom: 2px solid $sitecolor;
}

.navigation__list li a:hover,
.navigation__list li a:active {
  padding: 8px 0px;
  border-bottom: 2px solid $sitecolor;
}

.togglenavigation {
  position: fixed;
  top: 30px;
  right: 30px;
  display: block;
  width: 30px;
  height: 22px;
  z-index: 210;
  color: $sitecolor !important;
  -webkit-tap-highlight-color: transparent;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  -webkit-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
}

.togglenavigation>span {
  top: 50%;
  position: absolute;
  display: block;
  height: 2px;
  width: 30px;
  margin-top: -1px;
  background: currentColor;
}

.togglenavigation>.tline-1 {
  margin-top: -11px;
}

.togglenavigation>.tline-4 {
  margin-top: 9px;
}

.is-active .togglenavigation>.tline-1 {
  opacity: 0;
}

.is-active .togglenavigation>.tline-2 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.is-active .togglenavigation>.tline-3 {
  opacity: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}

.is-active .togglenavigation>.tline-4 {
  opacity: 0;
}