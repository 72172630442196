.intro {
  & .logo {
    height: 100px;
    width: auto;
    float: left;
    margin-top: 30px;
    margin-left: 30px;

    @media screen and (min-width: $breakpoint-medium - 1) {
      display:none;
    }
  }
}

.navigation {
  & .logo {
    height: 100px;
    width: auto;
    float: left;
    margin-top: 30px;
    margin-left: 20px;
    &.is-scrolled {
      margin: 3px 0px;
      height: 50px;
    }

    transition: height 250ms, margin 250ms;

    @media (max-width : ($breakpoint-medium - 1)) {
      display: none;
    }
  }
}